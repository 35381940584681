import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import * as styles from "./ImageCard.module.scss"

const ImageCard = ({ item, id }) => {
  const { title, description, buttons } = item
  const image = getImage(item?.image1[0]?.localFile)
  return (
    <Container className={styles.card}>
      <div className={`d-none d-md-none d-lg-block ${id > 6}`}>
        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={image}
          alt={title}
          className={id > 6}
        />
      </div>
      <div className={`${styles.content} d-none d-md-none d-lg-block`}>
        <h2 className="pl-3 pb-3 main-heading-36">{title}</h2>
        <div
          className="mb-3 pl-3"
          dangerouslySetInnerHTML={{ __html: description?.description }}
        />
        <div className="pl-2">
          <Link to={`/${buttons[0]?.url}/`}>
            <Button className="btn_white_border">Learn More</Button>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default ImageCard
