import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Insights from "../components/common/Insights-section/Insights"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/industries-sections/Banner"
import ImageCard from "../components/industries-sections/ImageCard"
import MobileImageCard from "../components/industries-sections/MobileImageCard"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/Explore_Industries_fd802bcad5.png"
    />
  )
}
const IndustriesPage = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)
  const portfolio = data?.strapiPage?.sections[0]

  const handleResize = () => {
    if (window && window?.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window && window.addEventListener("resize", handleResize)
    return () => window && window.removeEventListener("resize", handleResize)
  }, [])
  useEffect(() => {
    handleResize()
  }, [])

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner breadCrumb={data?.strapiPage?.buttons} />
      <div style={{ backgroundColor: "#f8f8f8" }} className="pb-3 p-md-0">
        {!isMobile
          ? portfolio.cards?.map((item, i) => (
              <ImageCard item={item} key={i} id={i} />
            ))
          : portfolio?.cards?.map((item, i) => (
              <MobileImageCard item={item} key={i} />
            ))}
      </div>
      <Insights />
      <WorkPortfolio bgColor="#f8f8f8" pt="0" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query getIndustriesData {
    strapiPage(slug: { eq: "industries" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default IndustriesPage
