import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Card from "react-bootstrap/Card"
import * as styles from "./PostCard.module.scss"
import { stringTruncate } from "../../utils"

const PostCard = ({ post, spacing }) => {
  const { featuredImage, isWP, readingTime,  writer } = post // prettier-ignore
  const categories = post?.categories || []
  const category = categories.length > 0 ? categories[0] : null
  const image = getImage(featuredImage?.localFile)

  return (
    <Card className={styles.card} style={{ margin: spacing ? "0 20px" : "" }}>
      <Link to={`/blog/${post?.slug}/`} style={{ minHeight: "238px" }}>
        {image ? (
          <GatsbyImage
            placeholder="blurred"
            decoding="async"
            loading="lazy"
            image={image}
            alt={post?.title}
            style={{ height: 238, maxWidth: "100%", objectPosition: "left" }}
          />
        ) : (
          <img
            src={featuredImage?.localFile?.publicURL}
            alt={post?.title}
            decoding="async"
            loading="lazy"
            style={{ height: 238, maxWidth: "100%", objectPosition: "left" }}
          />
        )}
      </Link>
      <Card.Body className={styles.containerA}>
        <div className={styles.info}>
          <Link
            to={`/blog/category/${category?.slug}/`}
            className={styles.category}
          >
            {category?.name}
          </Link>
          <div className={styles.authurName}>
            <Link to={`/author/${writer?.slug}/`}>
              <div className="align-items-center d-flex">
                <small className={styles.avatar}>{writer?.name}</small>
                <small className={`${styles.readTime} `}>
                  {isWP ? `${readingTime || 0} min read` : readingTime}
                </small>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.contBody}>
          <div className={styles.title}>
            <Link to={`/blog/${post?.slug}/`}>
              <h3 className={styles.innerText}>
                {stringTruncate(post?.title, 55)}
              </h3>
            </Link>
          </div>
          <div className={styles.description}>
            {stringTruncate(post?.seo?.metaDesc, 100)}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PostCard
