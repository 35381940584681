// extracted by mini-css-extract-plugin
export var authurName = "PostCard-module--authurName--1a5da";
export var avatar = "PostCard-module--avatar--de8d8";
export var card = "PostCard-module--card--1bf0b";
export var category = "PostCard-module--category--1da2b";
export var categoryName = "PostCard-module--categoryName--a4ec1";
export var contBody = "PostCard-module--contBody--f4ec3";
export var containerA = "PostCard-module--containerA--a02e0";
export var description = "PostCard-module--description--e9ad7";
export var imgContainer = "PostCard-module--imgContainer--9e90e";
export var info = "PostCard-module--info--28cf8";
export var innerText = "PostCard-module--innerText--611a5";
export var readTime = "PostCard-module--readTime--10902";
export var title = "PostCard-module--title--4f66f";