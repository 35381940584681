import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import * as styles from "./mobileImageCard.module.scss"

const MobileImageCard = ({ item }) => {
  const { title, description, buttons } = item
  const image = getImage(item?.image2[0]?.localFile)
  return (
    <Container className="p-3 p-md-5">
      <Card className={styles.card}>
        <Card.Body>
          <div>
            <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              image={image}
              alt={title}
            />
          </div>
          <div>
            <h2 className="pl-3 pb-3 pt-3 main-heading-36">{title}</h2>
            <div
              className="mb-3 pl-3"
              dangerouslySetInnerHTML={{ __html: description?.description }}
            />
            <div className="pl-2 pt-2">
              <Link to={`/${buttons[0]?.url}/`}>
                <Button className="btn_black_border">Learn More</Button>
              </Link>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default MobileImageCard
