import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import PostCard from "../PostCard"
import * as styles from "./Insights.module.scss"
import "./Insights.scss"

const Insights = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogPost(sort: { fields: publishedOn, order: DESC }, limit: 9) {
        nodes {
          readingTime
          publishedOn(formatString: "DD MMMM, YYYY")
          slug
          title
          seo {
            metaDescription
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          categories {
            name
            slug
          }
          writer {
            name
            slug
          }
        }
      }
    }
  `)

  const postsData = data?.allStrapiBlogPost?.nodes
  var posts = []
  postsData?.map(post => {
    return posts.push({
      slug: post?.slug,
      writer: post?.writer,
      featuredImage: post?.featuredImage,
      title: post?.title,
      seo: {
        metaDesc: post?.seo.metaDescription,
      },
      publishedOn: post?.publishedOn,
      isWP: false,
      readingTime: post?.readingTime,
      categories: post?.categories,
    })
  })

  const slide1 = useRef(null)

  const settings = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    infinite: false,
    arrows: false,
    lazyLoad: "ondemand",
    dotsClass: "button__bar_Insight",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
        },
      },
    ],
  }

  return (
    <section className={styles.section}>
      <div className="text-center mb-3 px-3">
        <h2 className={`mb-5 main-heading-36`}>Insights</h2>
      </div>
      <Container className="px-0 px-md-5">
        <Slider {...settings} ref={slide1}>
          {posts?.map((post, i) => (
            <div key={i} className="px-4 pb-5">
              <PostCard post={post} insights={true} />
            </div>
          ))}
        </Slider>
      </Container>
      <div className="text-center mt-5 pt-5 ">
        <Link to="/blog/">
          <Button className="btn_black_border">View All</Button>
        </Link>
      </div>
    </section>
  )
}

export default Insights
