import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import { Link } from "gatsby"

const Banner = ({ breadCrumb }) => {
  return (
    <section className={styles.section}>
      <Container>
        <nav className="mb-4 text-white">
          <Link to={`/`} className="text-white">
            Home
          </Link>
          {" » "}
          {breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-white">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-white">{val?.title}</span>
            )
          )}
        </nav>
        <div className="text-light text-center">
          <h1 className={styles.mainHeading}>Industries We Serve</h1>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={11} lg={10}>
              <p className="h4 font-weight-normal">
                InvoZone provides global enterprises with dedicated software
                development outsourcing teams to help them be successful,
                competitive, and innovative.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Banner
